<template>
  <div class="app-table app-table--block" @click="handleClick">
    <div class="app-table__row" v-for="(row, index) in rows" :key="index">
      <slot :name="row" />
    </div>
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits(['click'])

const slots = useSlots()

const rows = computed(() => {
  return Object.keys(slots)
})

function handleClick(row) {
  emit('click', row)
}
</script>
